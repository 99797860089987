import {Flex, Image } from '@chakra-ui/react'
import React from 'react'
import pageNotFound from '../images/pageNotFound.png'

const NotFound = () => {
  return (
    <Flex justifyContent={'center'} w={'100%'}>
        <Image w={'60%'} src={pageNotFound}/>
    </Flex>
  )
}

export default NotFound