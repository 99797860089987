import { RoutePaths } from "./routePaths";

export const HeaderMenuList = [
  {
    name: "HOME",
    path: RoutePaths.Home,
  },
  {
    name: "PRODUCTS",
    path: RoutePaths.Products,
  },
  {
    name: "ABOUT US",
    path: RoutePaths.AboutUs,
  },
  {
    name: "CONTACT US",
    path: RoutePaths.ContactUs,
  }
];