import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { RoutePaths } from '../enum/routePaths'
import LandingPage from '../pages/LandingPage'
import Products from '../pages/Products'
import AboutUs from '../pages/AboutUs'
import ContactUs from '../pages/ContactUs'
import ProductDetailView from './Products/ProductDetailView'
import NotFound from '../pages/NotFound'

const Content = () => {
  return (
    <>
    <Routes>
        <Route path={RoutePaths.Home} element={<LandingPage/>}/>
        {/* <Route path={RoutePaths.Products} element={<Products/>}/> */}
        <Route path={'/products/:type'} element={<Products/>}/>
        {/* <Route path={'/products/:type/:path'} element={<Products/>}/> */}
        <Route path={'/products/:type/:path'} element={<ProductDetailView/>}/>
        <Route path={RoutePaths.AboutUs} element={<AboutUs/>}/>
        <Route path={RoutePaths.ContactUs} element={<ContactUs/>}/>
        <Route path='*' element={<NotFound/>}/>
    </Routes>
    </>
  )
}

export default Content