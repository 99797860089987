import { Box, Center, Flex, Heading, SimpleGrid, VStack} from '@chakra-ui/react'
import React from 'react'
import ProductItemCard from './ProductItemCard'

const OurProducts = () => {
    return (
        <Box  px={['20px','30px','60px','80px']}>
             <Center>
                <VStack>
                    <Heading as={'h2'} textAlign={'left'}>Our Products</Heading>
            <Box width={'5.4rem'} h={'5px'} bgColor={'#394336'} />
                </VStack>
             </Center>
            <Flex flexDirection={'column'} mt={4} fontFamily={'Rubik'} alignItems={'center'} >             
                <Flex alignItems={'center'} justifyContent={'center'}>
                    <SimpleGrid columns={[1,2, 3, 3, 3]} spacingY={10} spacingX={['10px','20px','80px','80px']} mt={5}>
                        <ProductItemCard category="Seeds" name="Seeds & Nuts" />
                        <ProductItemCard category="Oil" name="Edible Oil" />
                        <ProductItemCard category="Spices" name="Spices" />
                        <ProductItemCard category="Pulses" name="Pulses" />
                        <ProductItemCard category="Grains" name="Grains" />
                        <ProductItemCard category="Herbs" name="Herbs" />
                    </SimpleGrid>
                </Flex>
            </Flex>
        </Box>
    )
}

export default OurProducts