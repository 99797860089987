import React, { useEffect, useState } from 'react';
import { Box, Flex, IconButton, Image} from '@chakra-ui/react';
import { ImageList } from '../../enum/Images';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6'

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    // Set up an interval to change the image every 5 seconds (adjust as needed)
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % ImageList.length);
    }, 3000);

    // Clear the interval when the component unmounts or when images change
    return () => clearInterval(intervalId);
  }, []);

  const nextItem = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % ImageList.length);
  };

  const prevItem = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + ImageList.length) % ImageList.length);
  };

  return (
    <Flex justify="center" align="center" w={'100%'}>
      <Box w={'100%'}>
        {/* Display the current item */}
        <Box w={'100%'} position="relative">
      <Box
        w={'100%'}
        h={['250px', '380px', '500px', '550px']}
        style={{
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Image
          src={ImageList[currentIndex]}
          alt="Your Alt Text"

          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
        <Flex
          justify="space-between"
          position="absolute"
          px={'15px'}
          top={['100px', '150px', '220px', '220px']}
          left={0}
          right={0}
        >
          <IconButton
            w={['40px', '60px', '60px', '60px']}
            h={['40px', '60px', '60px', '60px']}
            icon={<FaArrowLeft />}
            isRound='true'
            color={'white'}
            bgColor='transparent'
            border={'2px solid white'}
            onClick={prevItem}
            _hover={{ bgColor: '#9F4103', border: 'none' }}
          />
          <IconButton
            w={['40px', '60px', '60px', '60px']}
            h={['40px', '60px', '60px', '60px']}
            icon={<FaArrowRight />}
            isRound='true'
            color={'white'}
            bgColor='transparent'
            border={'2px solid white'}
            onClick={nextItem}
            _hover={{ bgColor: '#9F4103', border: 'none' }}
          />
        </Flex>
      </Box>
    </Box>
      </Box>
    </Flex>
  );
};

export default Carousel;
