// ************************** PRODUCT ITEMS *****************************
// import seasme_seeds from "../images/Products/ProductsItem/seasmeseeds.webp";
import peanuts from "../images/Products/ProductsItem/peanuts.jpg";
import blanchedpeanuts from "../images/Products/ProductsItem/blanchedpeanuts.jpg";
import coriander from "../images/Products/ProductsItem/coriander.jpg";
import bayleaf from "../images/Products/ProductsItem/bayleaf.jpg"
import black_pepper from "../images/Products/ProductsItem/blackpepper.jpg"
import cardamon from "../images/Products/ProductsItem/cardamom.jpg"
import cinnamon from "../images/Products/ProductsItem/cinnamon1.jpg"
import cloves from "../images/Products/ProductsItem/cloves.jpg"
import cumin from "../images/Products/ProductsItem/cuminseeds.jpg"
import fenugreek from "../images/Products/ProductsItem/fenugreekseeds.jpg"
import ginger from "../images/Products/ProductsItem/ginger1.jpg"
import mustard from "../images/Products/ProductsItem/mustard seeds.jpg"
import red_chilli from "../images/Products/ProductsItem/redchilli.jpg"
import turmeric from "../images/Products/ProductsItem/turmeric2.jpg"
import black_chana from "../images/Products/ProductsItem/desichana.jpg"
import black_eyed_beans from "../images/Products/ProductsItem/blackeyedpeas.jpeg"
import chickpeas from "../images/Products/ProductsItem/chickpeas.jpg"
import green_gram from "../images/Products/ProductsItem/greengram.jpeg"
import kidney_beans from "../images/Products/ProductsItem/kidneybeans.jpg"
import pegion_peas from "../images/Products/ProductsItem/yellowpigeonpeas.jpg"
import red_lentils from "../images/Products/ProductsItem/redlentils.jpg"
// import wheat from "../images/Products/ProductsItem/wheat.jpg"

// ************************ PRODUCT DATA *************************



export const productTitle = [
    {
        name: 'Sesame Seeds',
        path:'seeds/sesame-seeds',
        items: []
    },
    {
        name: 'Ground Nuts',
        path:'ground-nuts',
        description: '',
        category: 'Nuts',
        items: [
            {
                name: 'Raw Peanuts',
                path: 'raw-peanuts',
                image: peanuts,
            },
            {
                name: 'Blanched Peanuts',
                path: 'blanched-peanuts',
                image: blanchedpeanuts,
            },
        ],
    },
    {
        name: 'Spices',
        category:'Spices',
        path: 'spices',
        description: `Immerse your senses in the vibrant tapestry of flavors that Indian spices bring to the world of cuisine. At Shree Vishnu Proteins, we celebrate the rich and aromatic legacy of Indian spices, renowned for transforming ordinary meals into extraordinary culinary experiences.Sourced directly from the spice fields of India, our spices are meticulously selected for their authenticity and quality. We take pride in delivering a taste of India to kitchens worldwide, where the enchanting blend of spices creates an unforgettable symphony of flavors.`,
        items: [
            {
                name: 'Coriander Seeds',
                path: 'coriander-seeds',
                image: coriander,
            },
            {
                name: 'Cumin Seeds',
                path: 'cumin-seeds',
                image: cumin,
            },
            {
                name: 'Mustard Seeds',
                path: 'mustard-seeds',
                image: mustard,
            },
            {
                name: 'Red chilli',
                path: 'red-chilli',
                image: red_chilli,
            },
            {
                name: 'Turmeric',
                path: 'turmeric',
                image: turmeric,
            },
            {
                name: 'Ginger',
                path: 'ginger',
                image: ginger,
            },
            {
                name: 'Bay Leaf',
                path: 'bay-leaf',
                image: bayleaf,
            },
            {
                name: 'Cardamom',
                path: 'cardamom',
                image: cardamon
            },
            {
                name: 'Black Pepper',
                path: 'black-pepper',
                image: black_pepper
            },
            {
                name: 'Cinnamon',
                path: 'cinnamon',
                image: cinnamon
            },
            {
                name: 'Cloves',
                path: 'cloves',
                image: cloves
            },
            {
                name: 'Fenugreek Seeds',
                path: 'fenugreek-seeds',
                image: fenugreek,
            },
            
        ]
    },
    {
        name: 'Pulses',
        path:'pulses',
        category:"Pulses",
        description: `Embark on a culinary and nutritional adventure with our premium pulses at Shree Vishnu Proteins. Pulses, including lentils, chickpeas, and beans, form the heart of diverse cuisines worldwide, offering not only delightful flavours but also a powerhouse of essential nutrients. Sourced from trusted farmers and growers, our pulses undergo rigorous quality checks to ensure they meet the highest standards.Whether you're a chef aiming to create exquisite dishes or a health-conscious consumer looking for protein-rich and fibre-packed options, our export-grade pulses are tailored to meet your needs.We take pride in facilitating the global distribution of these nutritional gems, connecting markets and contributing to a healthier, tastier world.`,
        items: [
            {
                name: 'Green Gram',
                path: 'green-gram',
                image: green_gram,
            },
            {
                name: 'Black Eyed Beans',
                path: 'black-eyed-beans',
                image: black_eyed_beans,
            },
            {
                name: 'Red Lentils',
                path: 'red-lentils',
                image: red_lentils,
            },
            {
                name: 'Yellow Pegion Peas',
                path: 'pigeon-peas',
                image: pegion_peas,
            },
            {
                name: 'Kidney Beans',
                path: 'kidney-beans',
                image: kidney_beans
            },
            {
                name: 'Black Chana',
                path: 'desi-chana',
                image: black_chana,
            },
            {
                name: 'Chickpeas',
                path: 'chickpeas',
                image: chickpeas
            }
        ]
    },
    {
        name: 'Wheat',
        path:'grains/wheat',
        items: [],
        // description: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Error, fuga! Obcaecati ratione hic tempore quaerat debitis eos! Voluptas iure ipsam nam, voluptate quas nisi temporibus a sapiente laudantium ea porro nihil officia dolor totam in numquam ipsa dolorem at libero rem itaque. Autem itaque sed esse, inventore quasi non, molestiae quia, molestias nostrum culpa reprehenderit. Nisi placeat quam, ab sint nemo sapiente provident veritatis, beatae incidunt nostrum illum, ex ducimus laborum tempore fugiat esse necessitatibus voluptate modi voluptates soluta deleniti.`,
        // category:"Grains",
        // items: [
        //     {
        //         name: 'Wheat',
        //         path: 'wheat',
        //         image: wheat
        //     },
        // ]
    }
   
]

export const productData = [
    {
        product: 'Sesame Seed',
        path: 'sesame-seeds',
        images: [
            require('../images/Products/seeds/seseme_seeds/seeds1.jpg'),
            require('../images/Products/seeds/seseme_seeds/seeds2.webp'),
            require('../images/Products/seeds/seseme_seeds/seeds3.jpg'),
            require('../images/Products/seeds/seseme_seeds/seeds4.webp'),
        ],
        description: `Renowned for their nutritional richness and culinary versatility, our sesame seeds stand as an exemplary choice for export, promising quality and authenticity.Sourced from select farms known for cultivating premium sesame crops in Gujarat state, our seeds are widely preferred because of their uniform colour and bold size.Our sesame seeds are characterized by their distinct flavour, aroma, and high oil content, making them a sought-after ingredient in various cuisines worldwide.Packed with essential nutrients and antioxidants, our export-quality sesame seeds cater to the growing demand for health-conscious choices in the international market.Rigorous quality control measures, including sorting and cleaning processes, guarantee that our seeds are free from impurities, delivering a product that meets the discerning requirements of global markets.Whether incorporated into baked goods, savoury dishes, or extracted for oil, our exported sesame seeds exemplify a commitment to delivering premium quality, flavour, and nutritional benefits to discerning customers around the world.`,
        table: {
            'Brand Name': 'Vishnu proteins or as per customer\'s wish',
            origin: 'Gujarat, India',
            quality: 'White sesame and Black sesame',
            purity: '99%, 99.5%, 99.90%',
            packaging: '1 kg, 5 kg, 10 kg, 5kg, 25 kg',
        }
    },
    {
        product: 'Cumin seeds',
        path: 'cumin-seeds',
        images: [
            require('../images/Products/spices/cumin/cumin1.webp'),
            require('../images/Products/spices/cumin/cumin2.webp'),
            require('../images/Products/spices/cumin/cumin3.webp'),
        ],
        description: `Cumin seeds have garnered international acclaim for their unmistakable flavor and aromatic richness, becoming a staple ingredient in kitchens worldwide.Renowned not only for their culinary significance but also for their medicinal properties, cumin seeds hold a special place in the traditions of various Asian countries.Their warm, earthy flavor profile adds depth to a myriad of dishes, making them an essential component in the culinary landscape of Asian, African, and Latin American cuisines.Sourced from carefully selected regions with optimal growing conditions, our cumin seeds undergo a comprehensive cultivation process to ensure they meet the highest quality standards.Rigorous quality checks, including sorting and cleaning procedures, are implemented to eliminate impurities, ensuring that only premium cumin seeds make their way to the international market.Elevate your culinary experience with our premium cumin seeds - a testament to quality, flavor, and the essence of global spice excellence.`,
        table: {
            'Brand Name': 'Vishnu proteins or as per customer\'s wish',
            style: 'dried',
            size: '4-6 mm',
            color: 'brownish with yellow',
            origin: 'Gujarat, India',
            quality: 'Singapore & Europe',
            type: 'Machine clean and sortex clean',
            purity: '98%, 99%, 99.50%',
            packaging: '1 kg, 5 kg, 10 kg, 5kg, 25 kg',
        }
    },
    {
        product: 'Wheat',
        path:'wheat',
        images: [
            require('../images/Products/grains/wheat/wheat1.jpg'),
            require('../images/Products/grains/wheat/wheat2.webp'),
            require('../images/Products/grains/wheat/wheat3.jpg'),
        ],
        description: `wheat is a type of cereal grain, used all over the globe as a staple diet in one form or another.Its transformation into wheat flour opens the door to a plethora of culinary creations, ranging from bakery delights and bread to pasta, noodles, and beyond. This versatile grain, with its widespread use, has become an integral part of daily diets worldwide.Beyond its role as a primary source of carbohydrates, wheat contributes substantially to the nutritional profile of human diets.Packed with essential dietary elements, wheat contains a commendable amount of fiber, proteins, Vitamin B complex, and minerals. This nutritional richness adds to its significance as a holistic and healthful dietary.Our exported wheat is characterized by its robust flavor, exceptional milling properties, and versatile applications in various culinary endeavours.`,
        table: {
            'Brand Name': 'Vishnu proteins or as per customer\'s wish',
            origin: 'Gujarat, India',
            type: 'Sharabati, Desi and others',
            processing: 'Machine clean and sortex cleaned',
            packaging: '25 kg, 50 kg',
        }
    },
    {
        product: 'Raw Peanuts',
        path: 'raw-peanuts',
        description: `Peanuts are a major source of edible oil and vegetable-based protein. It contains 45-50% oil and 24-30% proteins along with other vitamins and minerals.We make sure that the Aflatoxin level in our peanuts is well under the export requirements of all nations. For this, we source our peanuts from farms which don't use or have minimal use of fertilizer and pesticides.`,
        images: [
            require('../images/Products/seeds/raw_peanuts/raw1.webp'),
            require('../images/Products/seeds/raw_peanuts/raw2.jpg'),
            require('../images/Products/seeds/raw_peanuts/raw3.jpeg'),
        ],
        table: {
            'Brand Name': 'Vishnu proteins or as per customer\'s wish',
            origin: 'Gujarat, India',
            variety: 'Bold, Java',
            'Count Size': '38/42,40/50,50/60,60/70',
            packaging: '10 KG, 20 KG, 50 KG',
        },
    },
    {
        product: 'Blanched Peanut',
        path: 'blanched-peanuts',
        images: [
            require('../images/Products/seeds/blanched_peanuts/blanched1.jpg'),
            require('../images/Products/seeds/blanched_peanuts/blanched2.webp'),
        ],
        description: `Blanched peanuts do not have red outer skin and are generally soft.  When the peanut is roasted the outer skin gets easily removed this gives roasted peanuts a crispier flavour and their self-life increases.  They are mostly used as snacks, in cooking, in chocolates and bakery products.We make sure that the Aflatoxin level in our peanuts is well under the export requirements of all nations. For this, we source our peanuts from farms which don't use or have minimal use of fertilizer and pesticides.We have an in-house laboratory with high-tech equipment to measure aflatoxin levels.Peanuts are processed in hygienic conditions to make sure that they are not contaminated in any way possible and to ensure high self-life.`,
        table: {
            'Brand Name': 'Vishnu proteins or as per customer\'s wish',
            origin: 'Gujarat, India',
            variety: 'Bold, Java',
            'Count Size': '38/42,40/50,50/60,60/70',
            packaging: '10 KG, 20 KG, 50 KG',
        },
    },
    {
        product: 'Turmeric',
        path:'turmeric',
        images: [
            require('../images/Products/spices/turmeric/turmeric1.jpg'),
            require('../images/Products/spices/turmeric/turmeric1.webp'),
            require('../images/Products/spices/turmeric/turmeric2.webp'),
        ],
        description: `Turmeric also referred to as the king of spices is widely used in kitchens and medical uses. It has been used in India since ancient times and India is the biggest supplier of turmeric and turmeric powder across the globe.It is used as a home remedy to treat many diseases such as indigestion, and colds, and helps to prevent cancer and many other diseases. It is a powerhouse of antioxidants, fiber, Vitamin B6, Potassium, Vitamin C and calcium.We source our turmeric from the Erode and Sangli regions in India, which are world famous for the production of the best quality turmeric with curcumin percentages ranging from 1.5% to 5%.`,
        table: {
            'Brand Name': 'Vishnu proteins or as per customer\'s wish',
            origin: 'India',
            colour: 'golden yellow',
            Shape: 'finger or powder',
            type: 'Fresh,  dried, Powdered ',
            packaging: '10 kg,15kg, 20 kg, 25 kg',
            curcumin: '1.5% to 5%',
        }
    },
    {
        product: 'Coriander seeds',
        path: 'coriander-seeds',
        images: [
            require('../images/Products/spices/coriander/coriander.jpg'),
            require('../images/Products/spices/coriander/coriander2.webp'),
            require('../images/Products/spices/coriander/coriander3.jpg'),
        ],
        description: `Coriander seeds are very famous for their aromatic smell and coriander powder is used almost in all the cuisine in many countries across the globe. It is also used as a flavouring agent in the food industry, in making soups and pickles and also used in medicinal purposes.It is a wonderful remedy to treat gastric problems and it helps in digestion. It improves our vision, rich in antioxidants, benefits heart and brain health, and is also good for the skin.We source our coriander seeds from the best farms and mandi houses and make sure that they are processed in an hygienic environment.`,
        table: {
            'Brand Name': 'Vishnu proteins or as per customer\'s wish',
            origin: 'India',
            style: 'Dried',
            grade : 'A',
            color : 'Brownish yellow',
            type: 'whole, split and powder',
            processing: 'Machine clean and sortex clean',
            packaging: '10 kg,15kg, 20 kg, 25 kg',
            purity: '98%, 99%, 99.50%',
            curcumin: '1.5% to 5%',
        }
    },
    {
        product: 'Ginger',
        path:'ginger',
        images: [
            require('../images/Products/spices/ginger/ginger1.jpg'),
            require('../images/Products/spices/ginger/ginger2.jpg'),
            require('../images/Products/spices/ginger/ginger2.webp'),
        ],
        description: `Ginger, known scientifically as Zingiber officinale, is a versatile and aromatic spice that has captivated taste buds and offered numerous health benefits for centuries IT has various health benefits which have been backed by scientific research now.There are various health benefits of ginger for example it helps speed up the digestion process and empty your stomach more quickly, Improves Immunity, aids in weight loss, makes skin healthier, may help to reduce cancer, improves skin health and boosts our immunity.We source our ginger from the best farms in south and northeast India, which are famous for the quality of the product.`,
        table: {
            'Brand Name': 'Vishnu proteins or as per customer\'s wish',
            origin: 'India',
            type: 'Fresh,  dried, Powdered ',
            packaging: '10 kg,15kg, 20 kg, 25 kg',
        }
    },
    {
        product: 'Green Gram',
        path:'green-gram',
        images: [
            require('../images/Products/pulses/green gram/green_gram_1.jpg'),
            require('../images/Products/pulses/green gram/green_gram2.jpg'),
            require('../images/Products/pulses/green gram/green_gram3.png'),
        ],
        description: `Mung beans, scientifically known as Vigna radiata, are a small, green legume belonging to the Fabaceae family. These beans have been cultivated for thousands of years and are native to Asia, where they have long been a dietary staple. Mung beans are valued for their nutritional content and versatility in cooking. Mung beans are a rich source of nutrients, including proteins, dietary fiber, vitamins (such as folate and vitamin B), and minerals (such as potassium, magnesium, and iron). They are also low in calories and fat. Mung beans are often sprouted to enhance their nutritional value. Mung bean sprouts are crisp, with a slightly sweet and nutty flavor. They are commonly used in salads, stir-fries, and spring rolls. Mung beans offer several health benefits. They are a good source of plant-based protein, making them an excellent choice for vegetarians and vegans. The fiber content aids in digestion, and the beans contain antioxidants that may have potential health-promoting effects.`,
        table: {
            'Brand Name': 'Vishnu proteins or as per customer\'s wish',
            origin: 'India',
            quality: 'Machine cleaned and sortex cleaned',
            packaging: '10 kg, 20 kg, 50 kg',
        }
    },
    {
        product: 'Black Eyed Beans',
        path:'black-eyed-beans',
        images: [
            require('../images/Products/pulses/black eyed beans/beans1.png'),
            require('../images/Products/pulses/black eyed beans/beans2.jpg'),
            require('../images/Products/pulses/black eyed beans/beans3.jpg'),
        ],
        description: `Black-eyed peas, scientifically known as Vigna unguiculata, are a variety of cowpeas that are widely consumed for their nutritional benefits and culinary versatility. Black-eyed peas are a nutrient-rich food, providing a good source of plant-based protein, dietary fiber, vitamins (such as folate and vitamin A), and minerals (including iron and potassium). These legumes offer several health benefits. They are a good source of plant-based protein, making them an excellent option for vegetarians and vegans. The dietary fiber content supports digestive health, and the beans are relatively low in fat. While originating in Africa, black-eyed peas have become a staple in many cuisines worldwide. They are used in dishes ranging from Indian dals to Southern American soul food.`,
        table: {
            'Brand Name': 'Vishnu proteins or as per customer\'s wish',
            origin: 'India',
            quality: 'Machine cleaned and sortex cleaned',
            packaging: '10 kg, 20 kg, 50 kg',
        }
    },
    {
        product: 'Chickpeas',
        path:'chickpeas',
        images: [
            require('../images/Products/pulses/chickpeas/chickpea1.webp'),
            require('../images/Products/pulses/chickpeas/chickpea2.jpg'),
        ],
        description: `Cultivated for thousands of years and is a staple in various cuisines around the world. Also known as garbanzo beans, chickpeas are valued for their versatility, nutty flavor, and numerous health benefits. Chickpeas are a nutrient-dense food, rich in plant-based protein, dietary fiber, vitamins, and minerals. They are particularly high in folate, iron, phosphorus, and manganese. Chickpeas are also a good source of complex carbohydrates. There are two main types of chickpeas—desi and kabuli. Desi chickpeas are smaller, darker, and have a rough coat. Kabuli chickpeas are larger, smoother, and have a beige color. Kabuli chickpeas are the variety commonly used in many Western dishes. Chickpeas offer numerous health benefits. The combination of protein and fiber in chickpeas helps in managing weight and supporting digestive health. The low glycemic index of chickpeas may contribute to better blood sugar control.`,
        table: {
            'Brand Name': 'Vishnu proteins or as per customer\'s wish',
            origin: 'India',
            quality: 'Machine cleaned and sortex cleaned',
            packaging: '10 kg, 20 kg, 50 kg',
        }
    },
    {
        product: 'Kidney Beans (Rajma)',
        path:'kidney-beans',
        images: [
            require('../images/Products/pulses/kidney beans/beans1.jpeg'),
            require('../images/Products/pulses/kidney beans/beans2.jpg'),
        ],
        description: `Kidney beans, scientifically known as Phaseolus vulgaris, are a popular and nutritious legume that is widely used in various cuisines around the world. Named for their kidney-like shape, these beans come in different varieties, including light and dark red, white, and speckled. Kidney beans are valued for their rich flavor, versatility, and numerous health benefits. Kidney beans are a nutrient-dense food, providing a good source of plant-based protein, dietary fiber, vitamins, and minerals. They are particularly high in folate, iron, potassium, and manganese. Kidney beans are an excellent source of plant-based protein, making them a valuable addition to vegetarian and vegan diets. When combined with grains such as rice or whole wheat, they form a complete protein source. The fiber content in kidney beans contributes to digestive health and helps in managing blood sugar levels. Additionally, their low glycemic index makes them a good choice for individuals with diabetes.`,
        table: {
            'Brand Name': 'Vishnu proteins or as per customer\'s wish',
            origin: 'India',
            quality: 'Machine cleaned and sortex cleaned',
            packaging: '10 kg, 20 kg, 50 kg',
        }
    },
    {
        product: 'Red Lentils',
        path:'red-lentils',
        images: [
            require('../images/Products/pulses/red lentils/red_lentils1.jpeg'),
            require('../images/Products/pulses/red lentils/red_lentils2.webp'),
        ],
        description: `Red lentils, scientifically known as Lens culinaris, are a type of legume that is widely cultivated for its edible seeds. These lentils are a popular ingredient in various cuisines due to their quick cooking time, delicate flavor, and nutritional value. Red lentils are particularly known for their vibrant orange-red color, which turns golden when cooked. Red lentils are a nutritious food, rich in plant-based protein, dietary fiber, and various essential nutrients. They provide a good source of iron, folate, potassium, and manganese. In Indian cuisine, red lentils are often used to make dal, a flavorful and comforting dish made with various spices. The lentils are cooked until soft and then seasoned with a mixture of spices, creating a nutritious and delicious dish. Red lentils offer several health benefits. They are low in fat, high in fiber, and can contribute to heart health, weight management, and blood sugar control. The fiber content also supports digestive health.`,
        table: {
            'Brand Name': 'Vishnu proteins or as per customer\'s wish',
            origin: 'India',
            quality: 'Machine cleaned and sortex cleaned',
            packaging: '10 kg, 20 kg, 50 kg',
        }
    },
    {
        product: 'Desi Chana',
        path:'desi-chana',
        images: [
            // require('../images/Products/pulses/back chana/black_chana1.jpg'),
            require('../images/Products/pulses/back chana/black_chana2.webp'),
            require('../images/Products/pulses/back chana/blac_chana3.webp'),
        ],
        description: `"Desi chana" refers to a variety of chickpeas that are native to the Indian subcontinent. The term "desi" is often used to denote indigenous or local varieties. Desi chana is a type of chickpea that has been cultivated in the Indian region for centuries and is a significant part of Indian cuisine. Desi chana has a nuttier and earthier flavor compared to kabuli chana. The taste is often described as robust and hearty. Like other varieties of chickpeas, desi chana is rich in plant-based protein, dietary fiber, vitamins, and minerals. It is a valuable component of a vegetarian or vegan diet The nutritional content of desi chana contributes to various health benefits. It supports muscle growth and repair due to its protein content, aids in digestion because of its fiber, and provides essential vitamins and minerals.`,
        table: {
            'Brand Name': 'Vishnu proteins or as per customer\'s wish',
            origin: 'India',
            quality: 'Machine cleaned and sortex cleaned',
            packaging: '10 kg, 20 kg, 50 kg',
        }
    },
    {
        product: 'Pigeon Peas',
        path:'pigeon-peas',
        images: [
            // require('../images/Products/pulses/pegion peas/peas1.jpeg'),
            require('../images/Products/pulses/pegion peas/peas2.webp'),
            require('../images/Products/pulses/pegion peas/peas2.jpg'),
        ],
        description: `Pigeon peas, scientifically known as Cajanus cajan, are a type of legume that is widely cultivated in tropical and subtropical regions. They are a valuable source of plant-based protein and are commonly used in various culinary dishes around the world. Pigeon peas are rich in protein, dietary fiber, vitamins, and minerals. They provide essential nutrients such as folate, magnesium, and potassium. Pigeon peas are a staple in many cuisines, particularly in regions like the Caribbean, Africa, Asia, and Latin America. They are used in a variety of dishes, including stews, curries, soups, and rice and pea combinations. Pigeon peas are commonly used to make dhal, a traditional dish in Indian cuisine. Dhal is a thick lentil soup or stew that is seasoned with various spices and often served with rice or flatbreads. Like other legumes, pigeon peas offer health benefits. They are a good source of plant-based protein, which is essential for muscle development and repair. The dietary fiber in pigeon peas supports digestive health.`,
        table: {
            'Brand Name': 'Vishnu proteins or as per customer\'s wish',
            origin: 'India',
            quality: 'Machine cleaned and sortex cleaned',
            packaging: '10 kg, 20 kg, 50 kg',
        }
    },
    {
        product: 'Bay leaves',
        path:'bay-leaf',
        images: [
            require('../images/Products/spices/Bay leaf/bayleaf1.webp'),
            require('../images/Products/spices/Bay leaf/bayleaf2.jpg'),
            require('../images/Products/spices/Bay leaf/bayleaf3.jpg'),
        ],
        description: `Bay leaves, scientifically known as Laurus nobilis, are aromatic leaves commonly used in cooking for their distinctive flavor and fragrance. These leaves come from the bay laurel tree, native to the Mediterranean region, but they are now cultivated in various parts of the world. The bay leaf has been a staple in culinary traditions for centuries, dating back to ancient times. In addition to their culinary use, bay leaves have also been employed for medicinal and ceremonial purposes in different cultures. Whether dried or fresh, bay leaves add depth and complexity to a wide range of savory dishes. Their inclusion in recipes enhances the overall flavor and aroma, making them a versatile and essential ingredient in kitchens around the world.`,
        table: {
            'Brand Name': 'Vishnu proteins or as per customer\'s wish',
            origin: 'India',
            type: 'Dried and Powdered',
            packaging: '10 kg, 15 kg, 20 kg',
        }
    },
    {
        product: 'Black pepper',
        path:'black-pepper',
        images: [
            require('../images/Products/spices/black pepper/black_pepper1.jpg'),
            require('../images/Products/spices/black pepper/black_pepper2.webp'),
            require('../images/Products/spices/black pepper/black_pepper3.png'),
        ],
        description: `Black pepper, scientifically known as Piper nigrum, is one of the most widely used and popular spices in the world. It comes from the dried and unripe berries, or peppercorns, of the pepper plant, which is native to the Indian subcontinent but is now cultivated in tropical regions globally. In the kitchen, black pepper is a versatile spice used in various forms, including whole peppercorns, cracked pepper, and ground pepper. Apart from its culinary applications, black pepper has been studied for its potential health-promoting properties. Piperine, the active compound in black pepper, has been researched for its antioxidant, anti-inflammatory, and digestive properties. It's important to note that while black pepper can be a flavorful addition to a balanced diet, it should be consumed in moderation.`,
        table: {
            'Brand Name': 'Vishnu proteins or as per customer\'s wish',
            origin: 'India',
            type: 'Whole and Powdered',
            packaging: '10 kg, 15 kg, 20 kg',
        }
    },
    {
        product: 'Cardamom',
        path:'cardamom',
        images: [
            require('../images/Products/spices/cardmon/cardmom1.jpg'),
            require('../images/Products/spices/cardmon/cardmom2.jpg'),
            require('../images/Products/spices/cardmon/cardmom3.jpg'),
            require('../images/Products/spices/cardmon/cardamom4.jpeg'),
        ],
        description: `Cardamom, often referred to as the "queen of spices," is a highly aromatic and flavorful spice derived from the seeds of plants belonging to the genera Elettaria and Amomum. The two most common types are green cardamom (Elettaria cardamomum) and black cardamom (Amomum subulatum). Cardamom has a complex flavor profile, offering a mix of citrus, floral, and spicy notes. The taste is both sweet and savory, making it a versatile spice used in both sweet and savory dishes. Commonly used in desserts, baked goods, and beverages (like chai tea), cardamom imparts a distinct and exotic flavor. Cardamom has been traditionally used in Ayurvedic medicine for its potential health benefits. It contains essential oils and antioxidants and is believed to have digestive and anti-inflammatory properties.We procure it from  Kerala in India where it is cultivated the most.`,
        table: {
            'Brand Name': 'Vishnu proteins or as per customer\'s wish',
            origin: 'India',
            size: '6 to 8 mm+',
            style : 'fresh and dried',
            grade: 'A', 
            quality: 'Machine cleaned and handpicked',
            type: 'Green & Black',
            packaging: '10 kg, 15 kg, 20 kg',
        }
    },
    {
        product: 'Cloves',
        path:'cloves',
        images: [
            require('../images/Products/spices/cloves/clove1.jpg'),
            require('../images/Products/spices/cloves/clove2.jpg'),
            require('../images/Products/spices/cloves/clove3.jpg'),
        ],
        description: `Cloves are aromatic flower buds derived from the evergreen tree Syzygium aromaticum, native to the Maluku Islands in Indonesia. They are one of the most well-known and widely used spices globally, valued for their warm, sweet, and slightly bitter flavor, as well as their rich aromatic qualities. Cloves have a distinctive and strong flavor characterized by sweetness, warmth, and a hint of bitterness. They contribute a rich and aromatic element to both sweet and savory dishes. Cloves are a versatile spice used in various culinary applications. They are a key component in spice blends such as garam masala and pumpkin spice. In traditional medicine, cloves have been attributed with potential health benefits. They contain essential oils like eugenol, which has antimicrobial and antioxidant properties. Cloves have been used to address dental issues. We procure it from Karnataka and Kerala in India where it is cultivated the most.`,
        table: {
            'Brand Name': 'Vishnu proteins or as per customer\'s wish',
            origin: 'India',
            quality: 'Machine cleaned and handpicked',
            packaging: '10 kg, 15 kg, 20 kg',
        }
    },
    {
        product: 'Fenugreek Seeds',
        path:'fenugreek-seeds',
        images: [
            require('../images/Products/spices/fenugreek seeds/seeds1.jpg'),
            require('../images/Products/spices/fenugreek seeds/fseeds2.webp'),
            require('../images/Products/spices/fenugreek seeds/fseeds3.jpg'),
        ],
        description: `Fenugreek (Trigonella foenum-graecum) is an herb that is widely cultivated for its aromatic seeds, which are commonly used as a spice in various cuisines. Both the leaves and seeds of the fenugreek plant are utilized, but the seeds, in particular, are prized for their unique flavor and potential health benefits. Fenugreek seeds are small, yellowish-brown, and roughly rectangular in shape. Fenugreek seeds have a distinctive flavor that is both slightly bitter and sweet, with a hint of nuttiness. The aromatic profile of these seeds makes them a popular choice in various spice blends.`,
        table: {
            'Brand Name': 'Vishnu proteins or as per customer\'s wish',
            origin: 'India',
            quality: 'Machine cleaned and handpicked',
            packaging: '10 kg, 15 kg, 20 kg',
        }
    },
    {
        product: 'Mustard Seeds',
        path:'mustard-seeds',
        images: [
            require('../images/Products/spices/mustard seeds/mseeds1.jpg'),
            require('../images/Products/spices/mustard seeds/mseeds2.png'),
            require('../images/Products/spices/mustard seeds/mseeds3.webp'),
        ],
        description: `Mustard seeds are small round seeds obtained from the mustard plant (genus Brassica), which belongs to the cruciferous family. These seeds are widely used as a spice in various culinary traditions around the world, providing a distinctive flavor and aroma to a wide range of dishes. Mustard seeds contain nutrients such as selenium, omega-3 fatty acids, and various antioxidants. They may have potential health benefits, including anti-inflammatory and anti-cancer properties. Mustard seeds are low in calories but rich in flavor, making them a healthy and flavorful addition to various dishes. In Cusines, mustard seeds are frequently used in tempering (tadka) to add flavor to dishes. They are also used in pickling, spice blends, and various curry preparations.`,
        table: {
            'Brand Name': 'Vishnu proteins or as per customer\'s wish',
            origin: 'India',
            style: 'Dried',
            quality: 'Machine cleaned and handpicked',
            type: 'Black & Brown',
            packaging: '10 kg, 15 kg, 20 kg',
        }
    },
    {
        product: 'Cinnamon',
        path:'cinnamon',
        images: [
            require('../images/Products/spices/cinnamon/cinnamon1.jpg'),
            require('../images/Products/spices/cinnamon/cinnamon2.jpg'),
            require('../images/Products/spices/cinnamon/cinnamon3.jpg'),
        ],
        description: `Cinnamon is a highly aromatic spice derived from the inner bark of trees belonging to the genus Cinnamomum. This beloved spice has been used for centuries, known for its sweet and warm flavor, as well as its versatility in both culinary and medicinal applications. Cinnamon is renowned for its sweet, warm, and slightly woody flavor. The aroma is rich and inviting, making it a popular choice in both sweet and savory dishes. In savory dishes, cinnamon is used in spice blends, marinades, and certain Middle Eastern or North African cuisines, adding depth and complexity to the flavor profile. Cinnamon has been associated with various health benefits. It contains compounds with antioxidant and anti-inflammatory properties. Some studies suggest that cinnamon may help regulate blood sugar levels and improve insulin sensitivity.`,
        table: {
            'Brand Name': 'Vishnu proteins or as per customer\'s wish',
            origin: 'India',
            quality: 'Machine cleaned and handpicked',
            type: 'Black & Brown',
            packaging: '10 kg, 15 kg, 20 kg',
        }
    },
    {
        product: 'Red Chilli',
        path:'red-chilli',
        images: [
            require('../images/Products/spices/red chilli/chilli.jpg'),
            require('../images/Products/spices/red chilli/red chilli 2.jpg'),
            require('../images/Products/spices/red chilli/red chilli 3.jpg'),
        ],
        description: `The red chilli, also known as chilli pepper or simply chilli, is a spice that belongs to the genus Capsicum. These spicy peppers are widely used in culinary applications around the world, adding heat and flavor to various dishes. Red chillies come in different shapes, sizes, and levels of spiciness, ranging from mild to extremely hot. Red chillies are a staple in many cuisines, including Indian, Mexican, Thai, and various others. They are used in a variety of forms, such as fresh, dried, powdered, or as a key ingredient in hot sauces and Consuming red chillies in moderation may have several health benefits. Capsaicin, the active component, is believed to have anti-inflammatory and metabolism-boosting properties. Some studies suggest that it may also help in pain relief.`,
        table: {
            'Brand Name': 'Vishnu proteins or as per customer\'s wish',
            origin: 'India',
            shape:- 'elongated', 
            type: 'whole chilli(with stem) or powder',
            color :- 'red', 
            size: '8-15 cm',
            packaging: '10 kg, 15 kg, 20 kg',
        }
    },
]