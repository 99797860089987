import { Box, Button, Card, CardBody, CardHeader, Center, Flex, FormControl, FormLabel, IconButton, Image, Input, InputGroup, InputLeftElement, Text, Textarea, useMediaQuery, useToast } from '@chakra-ui/react'
import React, {useState } from 'react'
import { LuUser } from 'react-icons/lu';
import { AiOutlineMail, AiOutlineComment } from "react-icons/ai";
import contactUs from '../images/contactus.png';
import { FiPhone, FiMail } from "react-icons/fi";
import { MdOutlineLocationOn } from "react-icons/md";
import { Link } from 'react-router-dom';
import { TabFile } from '../utils/GeneralFunction';
import emailjs from '@emailjs/browser';

const ContactUs = () => {
  const isSmallScreen = useMediaQuery("(max-width: 768px)")[0];
  const toast = useToast();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  TabFile('Contact Us');

  const sendEmail = (e) => {
    e.preventDefault();

    const serviceId = 'service_gabbm5d';
    const templateId = 'template_j7fiy3r';
    const publicKey = 'HIJhO0F-K4zt8JlKU';

    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: 'Vishnu Proteins',
      message: message,
    }
    emailjs.send(serviceId,templateId,templateParams,publicKey)
      .then((result) => {
          toast({
            title: 'Email Sent Successfully!',
            position: 'top-right',
            status: 'success',
            duration:'90000',
            isClosable: true,
          });
          setName('');
          setEmail('');
          setMessage('');
      }, (error) => {
          toast({
            title: error.text,
            status: 'error',
            position: 'top-right',
            duration:'90000',
            isClosable: true,
          });
      });
  };

  return (
    <Box w={'100%'}>
      <Box w={'100%'} h={'300px'} as={Flex} alignItems={'center'} justifyContent={'center'} bgColor={'#94B78A'}>
        <Text fontSize={'32px'} fontFamily={'Rubik'} fontWeight={'500'} color={'#394336'}>Contact Us</Text>
      </Box>
      <Flex w={'100%'} mt={'60px'} gap={[3, 0]} flexDirection={['column', 'row']} justifyContent={'space-between'} px={['20px', '20px', '120px']}>
        <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'} gap={2} fontFamily={'Rubik'}>
          <a href='tel:+919825305120'>
            <IconButton icon={<FiPhone size={'35px'} />} px={'15px'} py={'30px'} borderRadius={'5px'} bgColor={'#94B78A'} color={'#394336'} />
          </a>
          <Text fontWeight={500}>Phone</Text>
          <Text color={'#394336'} fontSize={['16px','14px','16px']}><a href='tel:+919825305120'>+91 9825305120</a></Text>
        </Flex>
        <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'} gap={2} fontFamily={'Rubik'}>
          <a href="mailto:info@vishnuproteins.com">
          <IconButton icon={<FiMail size={'35px'} />} px={'15px'} py={'30px'} borderRadius={'5px'} bgColor={'#94B78A'} color={'#394336'} />
          </a>
          <Text fontWeight={500}>Email</Text>
          <Text color={'#394336'} fontSize={['16px','14px','16px']}><a href="mailto:info@vishnuproteins.com">info@vishnuproteins.com</a></Text>
        </Flex>
        <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'} gap={2} fontFamily={'Rubik'}>
          <a href="https://maps.app.goo.gl/rGMg167iXdM5rar48">
          <IconButton icon={<MdOutlineLocationOn size={'35px'} />} px={'15px'} py={'30px'} borderRadius={'5px'} bgColor={'#94B78A'} color={'#394336'} />
          </a>
          <Text fontWeight={500}>Location</Text>
          <Link>
            <Text color={'#394336'} fontSize={['16px','14px','16px']}>
              <a href="https://maps.app.goo.gl/rGMg167iXdM5rar48">View on Google Map</a>
              </Text>
          </Link>
        </Flex>
      </Flex>
      <Flex mb={'40px'} w={'100%'} mt={'80px'} px={['20px', '60px', '100px']} alignItems={'center'} justifyContent={'center'}>
        <Box flex={1}>
            <form onSubmit={sendEmail}>
            <Center>
              <Card w={isSmallScreen ? ['95%','85%'] : '95%'} fontFamily={'Rubik'} px={['5px', '10px']}>
              <CardHeader>
                <Text fontWeight={400} fontSize={'28px'}>Get In Touch !</Text>
              </CardHeader>
              <CardBody>
                  <FormControl isRequired>
                    <FormLabel fontSize={'14px'}>Name</FormLabel>
                    <InputGroup>
                      <InputLeftElement pointerEvents='none'>
                        <LuUser />
                      </InputLeftElement>
                      <Input fontSize={'13px'} type='text' placeholder='Name:' value={name} onChange={(e) => setName(e.target.value)}/>
                    </InputGroup>
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel fontSize={'14px'}>Email</FormLabel>
                    <InputGroup>
                      <InputLeftElement pointerEvents='none'>
                        <AiOutlineMail />
                      </InputLeftElement>
                      <Input fontSize={'13px'} type='email' placeholder='Email:' value={email} onChange={(e) => setEmail(e.target.value)}/>
                    </InputGroup>
                  </FormControl>
                <FormControl isRequired mt={'10px'}>
                  <FormLabel fontSize={'14px'}>Message</FormLabel>
                  <InputGroup>
                    <InputLeftElement pointerEvents='none'>
                      <AiOutlineComment />
                    </InputLeftElement>
                    <Textarea fontSize={'13px'} pt={'11px'} pl={'35px'} type='text' placeholder='Message:' value={message} onChange={(e) => setMessage(e.target.value)}></Textarea>
                    {/* <Input fontSize={'13px'} type='text' placeholder='Message:' value={message} onChange={(e) => setMessage(e.target.value)}/> */}
                  </InputGroup>
                </FormControl>
              </CardBody>
              <CardHeader>
                <Button w={'100%'} bgColor={'#394336'} color={'white'} fontWeight={400} type='submit'>Send Message</Button>
              </CardHeader>
            </Card>
            </Center>
            </form>
        </Box>
        {!isSmallScreen && (
          <Box as={Flex} alignItems={'center'} justifyContent={'center'} flex={1}>
          <Image src={contactUs} h={'400px'} />
          </Box>
        )}
      </Flex>
      <Box mb={'-60px'}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3699.3456294768052!2d70.793703!3d21.998064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDU5JzUzLjAiTiA3MMKwNDcnMzcuMyJF!5e0!3m2!1sen!2sin!4v1704992061565!5m2!1sen!2sin"
          width="100%"
          height="300"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          title='Map'
          referrerPolicy="no-referrer-when-downgrade"
        />
      </Box>
    </Box>
  )
}

export default ContactUs