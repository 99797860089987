import { Box, Button, Center, Flex, Heading, Image, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import SpicesMap from '../../images/LandingPage/spices_map.png'
import { useNavigate } from 'react-router-dom'

const Intro = () => {
  const navigate = useNavigate();
  return (
    <Box w={'100%'} fontFamily={'Rubik'} alignItems={'center'} mt={10} px={['15px','30px','60px', '80px']}>
      <Center>
        <VStack>
          <Heading as={'h2'}>What We Do?</Heading>
          <Box width={'4rem'} h={'5px'} bgColor={'#394336'} />
        </VStack>
      </Center>
      <Box mt={3} textAlign={'justify'} justifyContent={'center'}>
      <Text>
        Shree Vishnu Proteins has firmly established itself as a preeminent player in the agro-products industry, boasting over a decade of active engagement in the field. Recognized today as a leading processor and exporter of diverse agricultural goods from India, our commitment to maintaining the highest standards of quality is unwavering. Our products are a true representation of our brand values, and we pride ourselves on delivering excellence without compromise.
      </Text><br />
      <Text>
        We source the finest agri products from the best fields, farmers, and APMC/Mandi markets across various states. This meticulous selection process ensures that our customers receive products of superior quality. By engaging in substantial bulk purchases, we leverage economies of scale to offer our products at the most competitive prices in the market.
      </Text><br />
      <Text>
        Our extensive product range caters to diverse needs, and we take pride in offering customized packaging solutions to meet the unique requirements of our clients. With a global outlook, we facilitate quick and hassle-free delivery of our goods across the world. This international presence is a testament to our commitment to providing top-tier agricultural products with efficient and reliable logistics.
      </Text><br />
        <Text>
          At Shree Vishnu Proteins, we continually strive to exceed expectations, offering not just products but a commitment to quality, customization, and global accessibility.
        </Text>
        <Center mt={3}>
          <Button bgColor={'#394336'} color={'white'} _hover={{bgColor:'#394336BB'}} onClick={() => {
            navigate('about-us')
          }}>Read More</Button>
        </Center>
      </Box>
      <Flex bgColor={'white'} w={'100%'} justifyContent={'center'} mt={3}>
        <Image src={SpicesMap} w={'85%'} />
      </Flex>
    </Box>
  )
}

export default Intro