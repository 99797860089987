import { Box, Center, Flex, Heading, SimpleGrid, VStack } from '@chakra-ui/react'
import React from 'react'
import ServiceUI from './ServiceUI'
import manufacutre from '../../images/LandingPage/manufacture1.png';
import process from '../../images/LandingPage/process2.png';
import export1 from '../../images/LandingPage/ship.png';
import trade from '../../images/LandingPage/trade1.png';

const sericeList = [
    {
        icon: manufacutre,
        name: 'Manufacturing',
    },
    {
        icon: export1,
        name: 'Export',
    },
    {
        icon: process,
        name: 'Processing',
    },
    {
        icon: trade,
        name: 'Trading',
    },
]

const OurServices = () => {
  return (
    <Box px={['20px','30px','60px','60px']} mt={'50px'}>
        <Center>
            <VStack>
                <Heading as={'h2'} textAlign={'left'}>Our Services</Heading>
        <Box width={'5rem'} h={'5px'} bgColor={'#394336'} />
            </VStack>
        </Center>
        <Flex w={'100%'} alignItems={'center'} justifyContent={'center'} mt={'30px'}>
            <SimpleGrid columns={[1,2,2,4]} spacing={[6,12,12,6]}>
            {sericeList.map((ele,i) => (
                <ServiceUI key={i} data={ele}/>
            ))}
        </SimpleGrid>
        </Flex>
    </Box>
    
  )
}

export default OurServices