import React from 'react';
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Card, CardBody, CardHeader, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { Link, useParams } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa6';
import { productTitle } from '../enum/products';
import { TabFile } from '../utils/GeneralFunction';
import NotFound from './NotFound';

const Products = () => {
  const params = useParams();
  const { type } = params;
  const selectedProduct = productTitle.find((prod) => {
    return prod.path === type;
  });
  TabFile(selectedProduct != null && selectedProduct !== undefined ?selectedProduct.name : 'Vishnu Proteins');

  return (
    <div>
      {selectedProduct != null && selectedProduct !== undefined ? (
        <Box w="100%">
          <Box w="100%" h="300px" as={Flex} alignItems="center" justifyContent="center" bgColor="#94B78A" position="relative">
            <Text fontSize="32px" fontFamily="Rubik" fontWeight="500" color="#394336">Products</Text>
            <Box position="absolute" bottom="-20px" px="15px" py="8px" borderRadius="6px" bgColor="white" boxShadow="0px 4px 25px 0px rgba(0,0,0,0.1)">
              <Breadcrumb separator={<FaChevronRight />}>
                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="/">Home</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} isCurrentPage>{selectedProduct.name}</BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>
            </Box>
          </Box>

          <Box px={['10px', '20px', '30px', '60px']} mt={'50px'}>
            <Card>
              <CardHeader>
                <Box>
                  <Text fontWeight={500} fontSize={'22px'}>{selectedProduct.name}</Text>
                  <Text>{selectedProduct.description}</Text>
                </Box>
              </CardHeader>
              <CardBody>
                <SimpleGrid columns={[2, 2, 3, 4, 4, 4]} spacing={8}>
                  {selectedProduct.items ? (
                    selectedProduct.items.map((item, i) => (
                      <Link to={`/products/${type}/${item.path}`} key={i}>
                        <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'} gap={2} _hover={{ fontWeight: 500, textDecoration: 'underline' }}>
                          <Box bgImage={item.image} bgPos={'center'} bgRepeat={'no-repeat'} bgSize={'cover'} w={['110px','140px']} _hover={{ transform: 'scale(1.1)', transition: 'transform 0.3s ease' }} h={['110px','140px']} borderRadius={'50%'} border={'4px solid white'} boxShadow='0px 0px 13px 3px rgba(0,0,0,0.1)'/>
                          <Text fontSize={'14px'}>{item.name}</Text>
                        </Flex>
                      </Link>
                    ))
                  ) : (null)}
                </SimpleGrid>
              </CardBody>
            </Card>
          </Box>
        </Box>
      ) : (
        <NotFound />
      )}
    </div>
  );
};

export default Products;
