import { Box, Center, Flex, Heading, Image, SimpleGrid, Text, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import { FiBox } from "react-icons/fi";
import mission from '../images/mission2.png';
import { TabFile } from '../utils/GeneralFunction';

const AboutUs = () => {
  const isSmallScreen = useMediaQuery("(max-width: 768px)")[0];
  TabFile('About Us');
  return (
    <Box w={'100%'}>
      <Box w={'100%'} h={'300px'} as={Flex} alignItems={'center'} justifyContent={'center'} bgColor={'#94B78A'}>
        <Text fontSize={'32px'} fontFamily={'Rubik'} fontWeight={'500'} color={'#394336'}>About Us</Text>
      </Box>
      <Flex flexDirection={['column','column','row']} px={['10px','30px','30px','80px']} gap={5} alignItems={'center'} justifyContent={'center'}>
        <Box flex={[1,1.5,1.5,1]} w={'100%'} mb={isSmallScreen ? '-110px' : 0}>
          <Center>
            <Image src={mission}  w={['100%','90%','476px','476px']} h={['auto','auto','476px','476px']}/>
          </Center>
        </Box>
        <Box flex={[1,1,1,1]} mt={'30px'} textAlign={'justify'} fontFamily={'Rubik'} overflow={isSmallScreen ? 'hidden' : 'scroll'} h={isSmallScreen? 'auto' : '476px'}>
        <Text>
          At Vishnu Proteins, we are passionate about delivering the finest products straight from our fields to your table. With a commitment to quality, sustainability, and customer satisfaction, we take pride in offering you a range of premium commodities.
        </Text><br />
        <Text>
          Shree Vishnu Proteins, situated in Rajkot, Gujarat is one of the leading manufacturers and processing houses for various Agri items including pulses, spices, peanuts, wheat, sesame, cumin, and coriander to name a few.
        </Text><br />
        <Text>
          The Saurashtra region of Gujarat is well renowned all across the globe for the cultivation of the highest quality of peanuts and different types of spices. Being located in this area gives us the advantage of sourcing the best produce and delivering them to our clients all across the globe.
        </Text><br />
        <Text>
          We source our raw materials from local farms located in different states of India which are famous for the cultivation of specific things. For instance, Gujarat for groundnut, onion from Nashik,   spices from Kerala and many others.
        </Text>
      </Box>
      </Flex>
      <Box fontFamily={'Rubik'} mt={'30px'} px={['10px','30px','30px','80px']}>
        <Heading fontFamily={'Rubik'} as={'h6'} textAlign={'left'}>Our Mission</Heading>
        <Box width={'5rem'} h={'5px'} bgColor={'#394336AA'} />
        <Text mt={5}>
          Our mission goes beyond delivering exceptional groundnut products. We're dedicated to nourishing lives by providing nutrient-rich options and sustaining lands through eco-friendly farming practices. Vishnu Proteins is not just a brand; it's a promise of quality and responsibility.
        </Text>
      </Box>
      <Box fontFamily={'Rubik'} mt={'30px'} px={['10px','30px','30px','80px']}>
        <Heading fontFamily={'Rubik'} as={'h6'} textAlign={'left'}>Why Choose us</Heading>
        <Box width={'5rem'} h={'5px'} mt={1} bgColor={'#394336AA'} />
        <SimpleGrid columns={[1, 2]} spacingX={'30px'} spacingY={'30px'} mt={'30px'}>
          <Box as={Flex} boxShadow={'0px 4px 25px 0px rgba(0,0,0,0.1)'} p={'20px'} gap={5} flexDirection={['column', 'row']}>
            <Box>
              <FiBox size={'40px'} color='#394336' />
            </Box>
            <Box>
              <Text fontSize={'18px'} fontWeight={500}>Farm-to-Table Excellence</Text>
              <Text>
                At Vishnu Proteins, we take pride in our farm-to-table approach. By managing every stage of production, from cultivation to processing, we ensure that you experience the unparalleled freshness and purity of our products.
              </Text>
            </Box>
          </Box>
          <Box as={Flex} boxShadow={'0px 4px 25px 0px rgba(0,0,0,0.1)'} p={'20px'} gap={5} flexDirection={['column', 'row']}>
            <Box>
              <FiBox size={'40px'} color='#394336' />
            </Box>
            <Box>
              <Text fontSize={'18px'} fontWeight={500}>Sustainability Focus</Text>
              <Text>
                Our commitment to sustainability extends to the heart of our operations. We employ eco-friendly practices, minimizing our environmental footprint and contributing to the well-being of the planet.
              </Text>
            </Box>
          </Box>
          <Box as={Flex} boxShadow={'0px 4px 25px 0px rgba(0,0,0,0.1)'} p={'20px'} gap={5} flexDirection={['column', 'row']}>
            <Box>
              <FiBox size={'40px'} color='#394336' />
            </Box>
            <Box>
              <Text fontSize={'18px'} fontWeight={500}>Quality You Can Trust</Text>
              <Text>
                When you choose Shree Vishnu Proteins, you're choosing a commitment to quality that exceeds industry standards. Trust us to deliver products that are not only flavorful but also adhere to the highest levels of quality.
              </Text>
            </Box>
          </Box>
          <Box as={Flex} boxShadow={'0px 4px 25px 0px rgba(0,0,0,0.1)'} p={'20px'} gap={5} flexDirection={['column', 'row']}>
            <Box>
              <FiBox size={'40px'} color='#394336' />
            </Box>
            <Box>
              <Text fontSize={'18px'} fontWeight={500}>Innovation and performance</Text>
              <Text>
                our production units are equipped with cutting-edge technologies that help us to produce the best material in minimal time.
              </Text>
            </Box>
          </Box>
        </SimpleGrid>
        <Box as={Flex} boxShadow={'0px 4px 25px 0px rgba(0,0,0,0.1)'} p={'20px'} gap={5} mt={'30px'} flexDirection={['column', 'row']}>
          <Box>
            <FiBox size={'40px'} color='#394336' />
          </Box>
          <Box>
            <Text fontSize={'18px'} fontWeight={500}>Hygienic production</Text>
            <Text>
              Maintaining strict hygiene practices is paramount in a groundnut processing unit to ensure the production of safe and high-quality products. Our state-of-the-art processing equipment undergoes thorough cleaning and sanitation routines to eliminate any potential contaminants and allergens, upholding the highest standards of food safety. We prioritize a clean and organized storage environment for both raw materials and finished products, minimizing the risk of cross-contamination.
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default AboutUs