import { Box, Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'

const ServiceUI = ({ data }) => {
  return (
    <Box w={['260px', '210px', '220px', '220px']} h={['260px', '210px', '220px', '220px']} bgColor={'#F5F0E9'} fontFamily={'Rubik'} borderRadius={"1rem"}>
      <Flex width={'100%'} h={'100%'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
        <Image src={data.icon} w={'90px'} h={'90px'} />
        <Text mt={3} fontWeight={'500'} bgColor={'#F9F7F3'} color={'#394336'} borderRadius={'5px'}
          fontSize={'16px'}
          px={'20px'}
          py={'9px'}>{data.name}</Text>
      </Flex>
    </Box>
  )
}

export default ServiceUI