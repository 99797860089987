import { Flex, Heading, Spacer, Text, VStack, useMediaQuery, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, useDisclosure, Menu, MenuButton, MenuList, Button, MenuItem, IconButton, Image, } from '@chakra-ui/react'
import React from 'react'
import logo from '../images/vishnu.png'
import { HeaderMenuList } from '../enum/MenuList'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { HiMenu } from 'react-icons/hi';
import '../font.css'
import { productTitle } from '../enum/products';

const Header = () => {
  // const isSmallScreen = useMediaQuery("(max-width: 1100px)")[0];
  const isNotSmallerScreen = useMediaQuery("(max-width:1100px)")[0];
  const location = useLocation();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isMenuOpen, onOpen: onMenuOpen, onClose: onMenuClose } = useDisclosure();
  const { pathname } = location;

  return (
    <>
      <VStack px={[0, 4]} height={'80px'} justify={'center'} boxShadow={'5px 5px 5px -1px rgba(0, 0, 0, .2)'} onMouseLeave={onMenuClose}>
        <Flex w={'100%'} alignItems={'center'} gap={5} bgColor={'white'} px={'30px'} h={'70px'} fontFamily={'Rubik'}>
          <Heading size={['md', 'md', 'lg']} fontFamily={'Rubik'}>
            <Link to={'/'}>
              <Flex gap={2}>
                <Image src={logo} w={'30px'} h={'30px'}/>
                Vishnu Proteins
              </Flex>
            </Link>
          </Heading>
          <Spacer />
          {!isNotSmallerScreen ? (
            HeaderMenuList.map((item, i) => (
              item.name === "PRODUCTS" ? (
                <Menu key={i} isOpen={isMenuOpen} onClose={onMenuClose}>
                  <MenuButton fontWeight={pathname.includes('products') ? 600: 400} bgColor={pathname.includes('products') ? '#394336': 'transparent'} color={pathname.includes('products') ? 'white': '#394336'} _hover={{bgColor: '#394336CC', color:'white'}} fontSize={'20px'} as={Button} onMouseEnter={onMenuOpen}>
                    {item.name}
                  </MenuButton>
                  <MenuList>
                    {productTitle.map((productItem, j) => (
                      <Link key={j} to={`/products/${productItem.path}`}>
                        <MenuItem >
                        {productItem.name}
                        </MenuItem>
                      </Link>
                    ))}
                  </MenuList>
                </Menu>
              ) : (
                <Button key={i} fontWeight={item.path === pathname ? 600 : 400} bgColor={item.path === pathname ? '#394336' : 'transparent'} color={item.path === pathname ? 'white' : '#394336'} _hover={{bgColor: '#394336CC', color:'white'}}>
                  <NavLink to={item.path} key={i}><Text fontSize={'20px'}>{item.name}</Text></NavLink>
                </Button>
              )
            ))
          ) : (
            <IconButton icon={<HiMenu color='#394336' onClick={() => onOpen()} />} border={'1.4px solid #394336'} borderRadius={'10px'} bgColor={'transparent'}/>
          )}
        </Flex>
      </VStack>
      <Drawer placement={'left'} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent >
          <DrawerHeader borderBottomWidth='2px' mb={2}>MENU</DrawerHeader>
          <DrawerBody m={0} p={0}>
            <VStack alignItems={'start'}>
              {HeaderMenuList.map((item, i) => (
                item.name === "PRODUCTS" ? (
                   <Menu transform={'none!important'} key={i} isOpen={isMenuOpen} onClose={() => {
                    onMenuClose();
                    onClose();
                   }}>
                      <MenuButton w={'100%'} borderRadius={0} fontWeight={pathname.includes('products') ? 600: 400} bgColor={pathname.includes('products') ? '#394336': 'transparent'} color={pathname.includes('products') ? 'white': '#394336'} _hover={{bgColor: '#394336CC', color:'white'}} fontSize={'20px'} as={Button} onMouseEnter={onMenuOpen}>
                        {item.name}
                      </MenuButton>
                      <MenuList>
                        {productTitle.map((productItem, j) => (
                          <Link key={j} to={`/products/${productItem.path}`}>
                            <MenuItem>
                              {productItem.name}
                            </MenuItem>
                          </Link>
                        ))}
                      </MenuList>
                    </Menu>
                ) : (
                  <Button key={i} w={'100%'} fontWeight={item.path === pathname ? 600 : 400} bgColor={item.path === pathname ? '#394336' : 'transparent'} _hover={{bgColor: '#394336CC', color:'white'}} color={item.path === pathname ? 'white' : '#394336'} borderRadius={0} onClick={() => {
                    navigate(`${item.path}`);
                    onClose();
                  }}>
                    <Text fontSize={'20px'}>{item.name}</Text>
                  </Button>
                )
              ))}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default Header