import { Button, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import bg1 from '../../images/LandingPage/bg1.webp';
import { useNavigate } from 'react-router-dom';

const ActionCard = () => {
  const navigate = useNavigate();
  return (
    <Flex
      w="100%"
      h="fit-content"
      background={`linear-gradient(360deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) 100%), url(${bg1})`}
      bgRepeat="no-repeat"
      bgSize="cover"
      alignItems="center"
      py="3rem"
      my="3rem"
      justifyContent="center"
      flexDirection="column"
      fontFamily="Rubik"
      textAlign="center"
    >
      <Text color="white" fontSize="2xl" fontWeight="bold" mb="2">
        Your Trusted Source for Quality Pulses and Grains
      </Text>
      <Text color="white" fontSize="sm" mb="4">
        From our field to your feast, Pure, Fresh and Nutrition Rich 
      </Text>
      <Button
        mt="20px"
        variant="outline"
        w="170px"
        h="50px"
        borderColor="white"
        color="white"
        fontWeight={500}
        borderRadius={6}
        _hover={{ bgColor: '#ffffff66', color: 'white' }}
        onClick={() => {
          navigate('contact-us')
        }}
      >
        Explore
      </Button>
    </Flex>
  );
};

export default ActionCard;



// import { Box, Button, Flex } from '@chakra-ui/react'
// import React from 'react'
// import bg1 from '../../images/LandingPage/bg1.webp';

// const ActionCard = () => {
//   return (
//     <Flex w={'100%'} mt={'50px'} h={'fit-content'} background={`linear-gradient(360deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) 100%), url(${bg1})`} bgRepeat={'no-repeat'} bgSize={'cover'} alignItems={'end'} justifyContent={'center'} fontFamily={'Poppins'}>
//       <Button my={'50px'} variant='outline' w={'170px'} h={'50px'} borderColor={'white'} color={'white'} fontWeight={500} borderRadius={6} _hover={{ bgColor: '#ffffff66', color: 'white' }}>
//         Explore
//       </Button>
//     </Flex>
//   )
// }

// export default ActionCard