import image1 from '../images/LandingPage/image.jpg';
// import image2 from '../images/LandingPage/image1.jpg';
import image2 from '../images/LandingPage/image2.jpg';
import image3 from '../images/LandingPage/out_2.png';
import image4 from '../images/LandingPage/out_3.png';
import image5 from '../images/LandingPage/out_4.png';

export const ImageList = [
    image1, image2, image3, image4, image5
    // "https://picsum.photos/id/1/900/600",
    // "https://picsum.photos/id/2/900/600",
    // "https://picsum.photos/id/3/900/600",
    // "https://picsum.photos/id/4/900/600",
    // "https://picsum.photos/id/5/900/600",
    // "https://picsum.photos/id/6/500/300",
    // "https://picsum.photos/id/7/500/300",
    // "https://picsum.photos/id/8/500/300",
    // "https://picsum.photos/id/9/500/300",
    // "https://picsum.photos/id/10/500/300",
    // "https://picsum.photos/id/11/500/300",
    // "https://picsum.photos/id/12/500/300",
    // "https://picsum.photos/id/13/500/300",
    // "https://picsum.photos/id/14/500/300",
    // "https://picsum.photos/id/15/500/300",
    // "https://picsum.photos/id/16/500/300",
    // "https://picsum.photos/id/17/500/300",
    // "https://picsum.photos/id/18/500/300",
    // "https://picsum.photos/id/19/500/300",
    // "https://picsum.photos/id/20/500/300",
    // "https://picsum.photos/id/21/500/300",
    // "https://picsum.photos/id/22/500/300",
    // "https://picsum.photos/id/23/500/300",
];