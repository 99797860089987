import React from 'react'
import Carousel from '../components/LandingPage/Carousel'
import Intro from '../components/LandingPage/Intro'
import OurProducts from '../components/LandingPage/OurProducts'
import OurServices from '../components/LandingPage/OurServices'
import ActionCard from '../components/LandingPage/ActionCard'
import { TabFile } from '../utils/GeneralFunction'

const LandingPage = () => {
  TabFile('Vishnu Proteins');
  return (
    <>
        <Carousel />
        <Intro/>
        <OurProducts/>
        <ActionCard />
        <OurServices />
        {/* Services */}
        {/* Experience */}
    </>
  )
}

export default LandingPage