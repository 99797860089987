import { Box, Divider, Flex, GridItem, Icon, IconButton, List, ListIcon, ListItem, SimpleGrid, Text } from '@chakra-ui/react'
import React from 'react'
import { FaChevronRight, FaFacebook, FaInstagram, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { FaLocationPin } from 'react-icons/fa6';
import { FiPhone, FiMail } from 'react-icons/fi';
import { Link } from 'react-router-dom';
// import { MdEmail } from 'react-icons/md';

const Footer = () => {
  return (
    <Box w="100%" mt="60px" fontFamily="Rubik" color="white">
      <SimpleGrid columns={[1,1,3,3]} bgColor="#404a3d">
        <Box as={GridItem} px="30px" py="40px">
          <Flex flexDir="column" align="flex-start">
            <Text fontSize="2xl" fontWeight="bold">
              Vishnu Proteins
            </Text>
             <Divider width={"3rem"} mb={3}/>
            <Text>
              From our field to your feast, Pure, Fresh and Nutrition Rich 
            </Text>
            <Flex mt="4">
              <IconButton
                size="md"
                icon={<FaFacebook />}
                color="#EDDD5E"
                borderRadius="5px"
                border="1px solid #EDDD5E"
                p="2"
                bgColor="transparent"
                marginRight="2"
              />
              <IconButton
                size="md"
                icon={<FaInstagram />}
                color="#EDDD5E"
                borderRadius="5px"
                border="1px solid #EDDD5E"
                p="2"
                bgColor="transparent"
                marginRight="2"
              />
              <IconButton
                size="md"
                icon={<FaTwitter />}
                color="#EDDD5E"
                borderRadius="5px"
                border="1px solid #EDDD5E"
                p="2"
                bgColor="transparent"
                marginRight="2"
              />
              <IconButton
                size="md"
                icon={<FaLinkedin />}
                color="#EDDD5E"
                borderRadius="5px"
                border="1px solid #EDDD5E"
                p="2"
                bgColor="transparent"
              />
            </Flex>
          </Flex>
        </Box>
        <Box as={GridItem} px="30px" py="40px">
          <Text fontSize="2xl" fontWeight="bold">
            Quick Links
          </Text>
            <Divider width={"3rem"} mb={3}/>
          <List spacing={2}>
            <ListItem>
              <ListIcon as={FaChevronRight} color="#EDDD5E" />
              <Link to={'/products/spices'}>Products</Link>
            </ListItem>
            <ListItem>
              <ListIcon as={FaChevronRight} color="#EDDD5E" />
              <Link to={'/about-us'}>About Us</Link>
            </ListItem>
            <ListItem>
              <ListIcon as={FaChevronRight} color="#EDDD5E" />
              <Link to={'/contact-us'}>Contact Us</Link>
            </ListItem>
          </List>
        </Box>
        <Box as={GridItem} px="30px" py="40px">
          <Text fontSize="2xl" fontWeight="bold">
            Contact Us
          </Text>
            <Divider width={"3rem"} mb={3}/>
          <List spacing={2}>
            <ListItem>
              <ListIcon as={FiMail} color="#EDDD5E" />
              {/* <Text onClick={() => {
                window.open('mailto:info@vishnuproteins.com')
              }}>info@vishnuproteins.com</Text> */}
              <a href="mailto:info@vishnuproteins.com" >info@vishnuproteins.com</a>
            </ListItem>
            <ListItem>
              <ListIcon as={FiPhone} color="#EDDD5E" />
              <a href='tel:+919825305120'>+919825305120</a>
            </ListItem>
            <ListItem>
              <ListIcon as={FiPhone} color="#EDDD5E" />
              <a href="tel:+918200247451">+918200247451</a>
            </ListItem>
          </List>
        </Box>
      </SimpleGrid>
      {/* <Divider /> */}
      <Box px="30px" py="40px"
        color="white"
        fontWeight="500"
        bgColor="#404a3d">
          <Text fontSize="2xl" fontWeight="bold">
            Address
          </Text>
            <Divider width={"3rem"} mb={3}/>
            <Flex mb={2} gap={2}>
              <Icon color="#EDDD5E" mt={2}><FaLocationPin size={'20px'}/></Icon>
              <Text>
                Factory: Plot no 7, behind galaxy pump, NH-7, Bhojapara, Gondal, Rajkot, Gujarat (360311)
              </Text>
            </Flex>
            <Flex mb={3} gap={2}>
              <Icon color="#EDDD5E" mt={2}><FaLocationPin size={'20px'}/></Icon>
              <Text>
                  Office: Shree Vishnu Proteins, Opp: kadva patel samaj, Gundala Road, Gondal, Rajkot, Gujarat (360311)
              </Text>
            </Flex>
      </Box>
      <Flex
        px="4"
        py="4"
        color="white"
        fontWeight="500"
        bgColor="#394336"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={['column','row','row','row']}
      >
        <Text fontWeight="bold">
          © VishnuProteins<span style={{ fontWeight: 'lighter' }}> All Right Reserved.</span>
        </Text>
        <Flex alignItems="center" flexDirection={['row','column','row','row']}>
          <Text fontWeight="light" mr={2}>
            Developed by
          </Text>
          <Link to="https://www.linkedin.com/in/krishna-viramgama-a83963173/" target='_blank' fontWeight="bold">
            Krishna Viramgama
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Footer