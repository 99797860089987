import React, { useEffect, useState } from "react";
import {
    Box,
    Heading,
    Text,
    Table,
    Tbody,
    Tr,
    Th,
    Td,
    Image,
    VStack,
    Flex,
    Divider,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
} from "@chakra-ui/react";
import { Link, useParams } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa6";
import { productData, productTitle } from "../../enum/products";
import NotFound from "../../pages/NotFound";


const ProductView = ({ productDetails }) => {
    const [selectedImage, setSelectedImage] = useState(productDetails.images[0]);

    useEffect(() => {
        setSelectedImage(productDetails.images[0]);
    }, [productDetails]);

    return (
        <>
            <Flex
                    w="100%"
                    p={['20px','40px','40px','40px']}
                    bg="white"
                    boxShadow="0px 0px 27px 6px rgba(0,0,0,0.1)"
                    borderRadius="md"
                    justifyContent="space-between"
                    textAlign="left"
                    flexDirection={["column","column","row","row"]} // Adjust flexDirection for responsiveness
                >
                    <Flex flexDirection={"column"} flex={1} alignItems={'center'}>
                        <Image
                            src={selectedImage}
                            alt={productDetails.product}
                            w={['300px','600px','500px','600px']}
                            h={['200px','400px','250px','400px']}                            
                            boxShadow='0px 0px 13px 3px rgba(0,0,0,0.1)'
                            objectFit="cover"
                            borderRadius="2rem" // Adjust borderRadius for responsiveness
                            mb={[4, 4, 0]} // Add margin bottom for responsiveness
                        />
                        <Flex
                            spacing={1}
                            width={"100%"}
                            flexDirection="row"
                            wrap={'wrap'}
                            gap="2"
                            marginTop="1rem"
                            alignItems="flex-start" justifyContent={'center'}
                        >
                            {productDetails.images && productDetails.images.length > 0 && productDetails.images.map((smallImage, i) => (
                                <Image
                                    key={i}
                                    src={smallImage}
                                    alt={productDetails.product}
                                    w={['55px','80px','60px','80px','100px']} h={['55px','70px','60px','75px','75px']}
                                    objectFit="cover"       
                                    border={'0.5px solid #ccc'}        
                                    outline={selectedImage === smallImage && '2px solid #0000009A'}
                                    outlineOffset={selectedImage === smallImage && '-5px'}
                                    borderRadius="1rem" // Adjust borderRadius for responsiveness
                                    mb={[4, 4, 0]} // Add margin bottom for responsiveness
                                    onClick={() => setSelectedImage(smallImage)}
                                    cursor="pointer"
                                />
                            ))}
                        </Flex>
                    </Flex>
                    <Box flex="1" marginLeft={[0, 0, "2rem"]}>
                        <Heading size="lg" color="teal.500">
                            {productDetails.product}
                        </Heading>
                        <Text mt={2} color="gray.600" textAlign={'justify'}>
                            {productDetails.description}
                        </Text>
                        <Divider my={4} />
                        <Heading size="md" color="teal.500">
                            Product Details
                        </Heading>
                        <Table variant="simple" size="sm" maxW="md" mt={4}>
                            <Tbody>
                                {Object.entries(productDetails.table).map(
                                    ([key, value], index) => (
                                        <Tr key={index}>
                                            <Th>{key}</Th>
                                            <Td>{value}</Td>
                                        </Tr>
                                    )
                                )}
                            </Tbody>
                        </Table>
                    </Box>
                </Flex>
        </>
    );
};

const ProductDetailView = () => {
    const params = useParams();
    const {type, path} = params;

    const productsTypeDetail = productTitle.find((prod) => {
        return prod.path === type || prod.path === `${type}/${path}`;
    })
    const productItem = productData.find(
        (product) => product.path === path
    );
    return (
        <div>
            {productItem ? (
                <VStack align="start" spacing={4}>
            <Box w="100%" h="300px" as={Flex} alignItems="center" justifyContent="center" bgColor="#94B78A" position="relative" mb={10}>
                <Text fontSize="32px" fontFamily="Rubik" fontWeight="500" color="#394336">Products</Text>
                <Box position="absolute" bottom="-20px" px="15px" py="8px" borderRadius="6px" bgColor="white" boxShadow="0px 4px 25px 0px rgba(0,0,0,0.1)">
                    <Breadcrumb separator={<FaChevronRight />}>
                        <BreadcrumbItem>
                            <BreadcrumbLink as={Link} to={"/"} color="teal.500">
                                Home
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        {productsTypeDetail.items.length > 0 && (
                            <BreadcrumbItem>
                            <BreadcrumbLink as={Link} to={`/products/${type}`} color="teal.500">
                                {productsTypeDetail.name}
                            </BreadcrumbLink>
                            </BreadcrumbItem> 
                        )}
                        <BreadcrumbItem>
                            <BreadcrumbLink isCurrentPage color="gray.500">
                                {productItem.product}
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Box>
            </Box>
            
            <Box w={'100%'} px={['10px','60px','60px','80px']}>
                <ProductView productDetails={productItem} />
            </Box>
        </VStack>
            ): (
                <NotFound />
            )}
        </div>
    );
};

export default ProductDetailView;