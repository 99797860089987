import React from 'react'
import Pulses from '../../images/LandingPage/Products/soy.png'
import Seeds from '../../images/LandingPage/Products/soy2.png'
import Oil from '../../images/LandingPage/Products/oil.png'
import Spices from '../../images/LandingPage/Products/soy3.png'
import Grains from '../../images/LandingPage/Products/soy4.png'
import Herbs from '../../images/LandingPage/Products/soy5.png'
import { Flex, Image, Text } from '@chakra-ui/react'

const ProductItemCard = ({ category, name }) => {
  const categoryImageMap = {
    Seeds,
    Oil,
    Spices,
    Pulses,
    Grains,
    Herbs,
  };

  return (
    <Flex
      flexDirection={'column'}
      w={["280px","200px","200px","250px"]}
      h={["200px","200px","200px","200px"]}
      borderRadius={'15px'}
      bgColor={'#F5F0E9'}
      alignItems={'center'}
      justifyContent={'center'}
      fontFamily={'Rubik'}
      _hover={{
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
        transform: 'scale(1.05)',
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
      }}
    >
      <Image src={categoryImageMap[category]} w={'70px'} h={'70px'} />
      <Text
        mt={3}
        fontWeight={'500'}
        bgColor={'#F9F7F3'}
        color={'#394336'}
        fontSize={'17px'}
        px={'20px'}
        py={'9px'}
        borderRadius={'5px'}
      >
        {name}
      </Text>
    </Flex>
  );
};
export default ProductItemCard